.upload-files-component_container {
    padding: 0 20px;
    @media (width >= 1024px) {
        margin: 20px 0;
    }
}

.upload-files-component_dropzone {
    padding: 20px;
    border: 1px dashed #818c99;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (width >= 1024px) {
        height: 150px;
    }
}

.upload-files-component_dropzone-text {
    color: #818c99;
    font-size: 14px;
    text-align: center;
}

.upload-files-component_uploaded-images {
    all: unset;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.upload-files-component_uploaded_image {
    all: unset;
    width: 100px;
    height: 100px;
    overflow: hidden;
    @media (width >= 1024px) {
        width: 200px;
        height: 200px;
    }
}

.upload-files-component_uploading-files {
    all: unset;
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 20px 0;
}

.upload-files-component_uploading-file {
    all: unset;
    display: flex;
    gap: 10px;
    align-items: center;
    color: #818c99;
    font-size: 16px;
}

.upload-files-component_uploading-file-name {
    overflow-wrap: break-word;
}

.upload-files-component_url-array {
    margin: 20px 0;
    width: 100%;
    min-height: 100px;
}

.upload-files-component_label {
    margin-bottom: 4px;
}

.upload-files-component_delete-image-button {
    all: unset;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #ffffffa3;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    backdrop-filter: blur(6px);
}

.upload-files-component_delete-file-button {
    all: unset;
    position: absolute;
    top: 0;
    right: 0;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
}

.upload-files-component_files-list {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.upload-files-component_file-preview {
    margin: 16px 0;
    position: relative;
    width: 100%;
}

.upload-files-component_image-preview {
    margin: 16px 0;
    position: relative;
}

.upload-files-component_image {
    width: 100px;
    height: 100px;
    object-fit: cover;
}
