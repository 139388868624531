.contract-assignment-list-item_container {
    all: unset;
}

.contract-assignment-list-item_button {
    all: unset;
    padding: 10px;
    display: flex;
    gap: 8px;
    background: #fff;
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 2px 24px 0px #00000014;
    cursor: pointer;
}

.contract-assignment-list-item_image {
    min-width: 100px;
    height: 100px;
}

.contract-assignment-list-item_main-block {
    display: flex;
    flex-direction: column;
}

.contract-assignment-list-item_price-block {
    color: #c06198;
    text-align: right;
    width: 80%;
    flex-grow: 1;
}

.contract-assignment-list-item_full-price {
    font-size: 18px;
    font-weight: 600;
}

.contract-assignment-list-item_required-payment {

}

.contract-assignment-list-item_info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}

.contract-assignment-list-item_title {
    font-weight: 600;
    font-size: 18px;
}

.contract-assignment-list-item_description {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    color: #818c99;
}

.contract-assignment-list-item_details {
    position: relative;
    display: flex;
    gap: 10px;
    width: 100%;
    color: #818c99;
}

.contract-assignment-list-item_info-row {
    display: flex;
    justify-content: space-between;
}

.contract-assignment-list-item_control {
    display: flex;
    flex-grow: 1;
    position: absolute;
    right: -10px;
    bottom: -10px;
}

.contract-assignment-list-item_control-icon {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 44px;
    height: 44px;
}
