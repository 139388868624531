.housings_container {
  @media (width >= 1024px) {
    margin: 16px 0;
    display: flex;
  }
}

.housings_list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0 20px;
  gap: 12px;
  @media (width >= 1024px) {
    margin: 0;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.housings_skeleton {
  height: 50px;
}

.housings_search-bar {
  padding: 0 20px;
  @media (width >= 1024px) {
    min-width: 350px;
  }
}
