.verify-email_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    a {
        all: unset;
        color: #c06198;
        font-weight: 600;
        text-align: center;
    }
}

.verify-email_loader {
    display: flex;
    justify-content: center;
}

.verify-email_title {
    font-size: 24px;
    text-align: center;
    margin: 20px;
}

.verify-email_description {
    color: #818c99;
    text-align: center;
}
