.share-block_container {
    display: flex;
    gap: 4px;
    justify-content: space-between;
}

.share-block_warning-icon {
    width: 22px;
    height: 22px;
    min-width: 22px;
    min-height: 22px;
}

.share-block_test-period {
    display: flex;
    gap: 4px;
    margin: 10px 0;
    padding: 10px;
}

.share-block_test-period-text {
    font-size: 14px;
    a {
        all: unset;
        font-weight: 600;
        color: #c06198;
    }
}
