.tabs_container {
  all: unset;
  display: flex;
  list-style: none;
  margin: 16px 20px;
  cursor: pointer;
}

.tabs_item {
  padding: 12px 0;
  background: #fff;
  flex: 1;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  box-sizing: border-box;
  color: #818c99;
}

.tabs_active {
  color: #000;
  border-bottom: 3px solid #c06198;
}
