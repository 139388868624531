.plans_container {
  display: flex;
  flex-direction: column;
}

.plans_main {
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 16px;
  height: 40px;
}

.plans_title {
  font-size: 24px;
}

.plans_list {
  box-sizing: border-box;
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 400ms ease-in-out;
  margin: 0;
}

.plans_expanded {
  height: auto;
}

.plans_item {
  list-style: none;
  display: flex;
  align-items: center;
  height: 64px;
  gap: 16px;
  border-bottom: 1px solid #818c99;
}

.plans_item-title {
}

.plans_icon {
  display: flex;
  align-items: center;
}

.plans_link-icon {
  margin-left: auto;
}

.plans_chevron {
  transform: rotate(90deg);
  transition: all ease-in 0.5s;
}

summary {
  display: flex;
  align-items: center;
  gap: 9px;
}

summary::-webkit-details-marker {
  display: none; /* This also hides the ::marker pseudo-element, but in Safari */
}

details {
  max-width: 500px;
  overflow: hidden;
  margin-top: 16px;
}

input:checked + details,
details:has(input:checked) {
  max-height: 800px; /* Set a max-height value enough to show all the content */
}

input:checked + details label::before,
details:has(input:checked) label::before {
  rotate: 90deg;
  transition: rotate 200ms ease-out;
}

details[open] + .plans_list {
  max-height: 800px;
  transition: max-height 500ms ease-in-out;
}

details[open] .plans_chevron {
  rotate: -180deg;
  transition: rotate 500ms ease-in-out;
}
