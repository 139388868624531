.housings_skeleton-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0 20px;
  gap: 4px;
}

.housings_skeleton-skeleton {
  height: 50px;
}
.housings-skeleton_search-bar {
  margin: 0 20px;
  @media (width >= 1024px) {
    min-width: 350px;
    width: 350px;
    height: 30px;
  }
}
