.housing_info_container {
    padding: 0 20px;
}

.housing_info_title {
    font-size: 24px;
}

.housing_info_info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 0;
    color: #818c99;
    font-size: 14px;
}

.housing_info_description {
    white-space: pre-line;
    transition: all ease-in 0.2s;
    cursor: pointer;
}

.housing_info_expanded {
    height: auto;
}
