.header_container {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px;
  font-size: 32px;
  height: 40px;
  align-items: center;

  @media (width >= 1024px) {
    background: #fff;
    box-shadow: 0px 2px 24px 0px #00000020;
    height: 56px;
    padding: 13px 20px;
    box-sizing: border-box;
  }
}

.header_logo {
  height: 30px;
  width: 150px;
}
