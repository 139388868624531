@keyframes dissolve {
  0% {
    background-color: #d4d5d5;
  }

  50% {
    background-color: #fafafa;
  }

  100% {
    background-color: #d4d5d5;
  }
}

.skeleton {
  width: 100%;
  animation: dissolve 1600ms ease-in-out 1ms infinite;
}
