.contract-assignment_container {
    padding: 0 20px;
    margin: 16px 0;

    ul {
        all: unset;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}

.contract-assignment_sort-button {
    all: unset;
    width: 22px;
    height: 22px;
}

.contract-assignment_add-button {
    padding: 16px;
}
