.side-menu_wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    backdrop-filter: blur(6px);
    z-index: 501;
}

.side-menu_container {
    position: fixed;
    box-sizing: border-box;
    padding: 36px;
    width: 100%;
    height: 100%;
    z-index: 502;
    background: #fff;
    bottom: 0;
    right: -100%;
    transition: all ease-in-out 300ms;
    font-size: 16px;

    @media (width >= 1024px) {
        width: 430px;
        right: -430px;
        box-shadow: 0px 0px 10px 1px #c6c6c6;
    }
}

.side-menu_opened {
    right: 0;
}

.side-menu_close {
    position: absolute;
    color: #fff;
    top: 20px;
    right: 20px;
    width: 36px;
    height: 36px;
    display: flex;
    cursor: pointer;
}

.side-menu_logout {
    all: unset;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #818c99;
    margin: 20px 0;
    cursor: pointer;
}

.side-menu_logout-icon {
    width: 28px;
    height: 28px;
}

.side-menu_item {
    padding-bottom: 20px;
    border-bottom: 1px solid #c8ced9;
}

.side-menu_item-title {
    font-size: 20px;
    font-weight: 600;
    margin: 10px 0;
}

.side-menu_link {
    all: unset;
    color: #c06198;
    font-weight: 600;
}
