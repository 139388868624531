.scroll-aware-block {
    position: fixed;
    width: 100%;
    color: #fff;
    text-align: center;
    transition: transform 0.3s ease-in-out;
    z-index: 500; /* Чтобы блок был поверх других элементов */
    box-sizing: border-box;
}

.scroll-aware-block.bottom {
    bottom: 0;
    transform: translateY(0);
}

.scroll-aware-block.top {
    top: 0;
    transform: translateY(0);
}

.scroll-aware-block.hide.bottom {
    transform: translateY(100%);
}

.scroll-aware-block.hide.top {
    transform: translateY(-100%);
}
