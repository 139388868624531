.about_container {
  margin: 20px 0;
  @media (width >= 1024px) {
      width: 400px;
      margin: 0;
  }
}

.about_description {
  color: #818c99;
}

.about_list {
  all: unset;
}

.about_list-item {
  display: flex;
  gap: 10px;
}

.about_icon {
  min-width: 24px;
  min-height: 24px;
  width: 24px;
  height: 24px;
}

.about_slider {
  border-radius: 10px;
  box-shadow: 0px 2px 24px 0px #00000014;
  overflow: hidden;
  --swiper-pagination-color: #ffc800;
  --swiper-navigation-color: #ffc800;
  aspect-ratio: 1 / 1;
  position: relative;
}

.about_slide {
  aspect-ratio: 1 / 1;
  position: relative;
  padding: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;
}

.about_side-title {
  margin: 26px 0 10px 0;
  color: #000;
  font-weight: 500;
  font-size: 32px;
}

.about_side-text {
}

.swiper-scrollbar {
  top: 10px !important;
  bottom: unset !important;
}
