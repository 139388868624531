.input_container {
  position: relative;
  width: 100%;
}

.input_main {
  all: unset;
  height: 40px;
  padding: 10px;
  background: #f2f3f5;
  border: 1px solid #d5d6d8;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
}

.input_label {
  margin-bottom: 4px;
}

.input_error {
  border: 1px solid #ff0000;
  background: #ffdddd;
}

.input_error-message {
  font-size: 14px;
  color: #ff0000;
}

.input_icon {
  width: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 10px;
  cursor: pointer;
}
