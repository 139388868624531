.error_container {
    padding: 20px;
}

.error_icon {
    display: flex;
    justify-content: center;
}

.error_title {
    font-size: 24px;
    text-align: center;
    margin: 20px;
}

.error_description {
    color: #818c99;
    text-align: center;
}

.error_retry_button {
    padding: 20px;
    display: flex;
    justify-content: center;
}
