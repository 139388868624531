.accordion-container {
    transition: max-height 0.3s ease-in-out;
}

.hidden-text {
    margin-top: 8px;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.3s ease, max-height 0.3s ease;
}

.hidden-text.show {
    opacity: 1;
    max-height: 1000px; /* Увеличена высота, чтобы полностью раскрывать содержимое */
    overflow: visible;
}

.actions {
    margin-top: 8px;
    display: flex;
    gap: 8px;
}

.more-link {
    color: #007bff;
    text-decoration: none;
}

.more-link:hover {
    text-decoration: underline;
}

.more-button {
    padding: 6px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.more-button:hover {
    background-color: #0056b3;
}
