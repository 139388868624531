.contract-assignment-item-skeleton_container {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.contract-assignment-item-skeleton_title {
    width: 100px;
    height: 24px;
}
.contract-assignment-item-skeleton_row {
    width: 250px;
    height: 14px;
}

.contract-assignment-item-skeleton_address {
    width: 200px;
    height: 14px;
}

.contract-assignment-item-skeleton_image {
    width: 100%;
    padding: 40%;
    box-sizing: border-box;
}
