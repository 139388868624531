.contract-assignment-item_container {
    padding: 0 20px;
}

.contract-assignment-item_title {
    font-size: 24px;
}

.contract-assignment-item_info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 0;
    color: #818c99;
    font-size: 14px;
}

.contract-assignment-item_description {
    white-space: pre-line;
    transition: all ease-in 0.2s;
}

.contract-assignment-item_layout {
    padding: 0 8px;
    border: none;
}

.contract-assignment-item_chevron {
    transform: rotate(180deg);
}
