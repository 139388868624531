.upload-files_container {
    padding: 0 20px;
    @media (width >= 1024px) {
        margin: 20px 0;
    }
}

.upload-files_dropzone {
    padding: 20px;
    border: 1px dashed #818c99;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (width >= 1024px) {
        height: 150px;
    }
}

.upload-files_dropzone-text {
    color: #818c99;
    font-size: 14px;
    text-align: center;
}

.upload-files_uploaded-images {
    all: unset;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.upload-files_uploaded_image {
    all: unset;
    width: 100px;
    height: 100px;
    overflow: hidden;
    @media (width >= 1024px) {
        width: 200px;
        height: 200px;
    }
}

.upload-files_uploading-files {
    all: unset;
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 20px 0;
}

.upload-files_uploading-file {
    all: unset;
    color: #818c99;
    font-size: 16px;
}

.upload-files_url-array {
    margin: 20px 0;
    width: 100%;
    min-height: 100px;
}
