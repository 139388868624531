.ReactModal__Overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(205 205 205 / 75%) !important;
    z-index: 501;
}

.modal_container {
    position: absolute;
    width: 100%;
    height: auto;
    background: #fdfdfd;
    bottom: 0;
    padding: 60px 20px 20px 20px;
    box-sizing: border-box;
    border-radius: 10px 10px 0 0;
    box-shadow: 0px 2px 24px 0px #00000014;
    @media (width >= 1024px) {
        width: 500px;
        bottom: auto;
        border-radius: 10px;
    }
}

.modal_main {

}

.modal_title {
    font-size: 28px;
    font-weight: 500;
    margin: 16px 0;
}

.modal_text {
    color: #818c99;
    margin: 16px 0;
}

.modal_close-icon {
    all: unset;
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 2px 24px 0px #00000014;
    box-sizing: border-box;
}
