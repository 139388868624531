.installment-skeleton_title {
  width: 250px;
  height: 28px;
  margin: 8px;
}
.installment-skeleton_block {
  width: 100%;
  height: 42px;
  margin: 38px 0;
}
