.first-payment_container {
  overflow: hidden;
  display: flex;
  gap: 6px;
  flex-direction: column;
}

.first-payment_input {
  all: unset;
  width: 100%;
  background: none;
  padding: 10px;
  color: #fff;
  font-size: 18px;
  border-bottom: 1px solid #c8ced9;
}

.first-payment_numbers {
  font-size: 20px;
  margin: 6px 0;
  font-weight: bold;
}
