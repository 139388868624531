.switcher_container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.switcher_toggle {
    width: 50px;
    height: 30px;
    background: #EBEDEF;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 2px;
    box-sizing: border-box;
    transition: all 0.5s;
}

.switcher_toggle-checked {
    justify-content: end;
    background: #c06198;
}

.switcher_circle {
    width: 26px;
    height: 26px;
    background: #fff;
    border-radius: 50%;
}

.switcher_label {

}
