.slider_container {
    aspect-ratio: 1 / 1;
    margin-bottom: 20px;
    position: relative;
}

.slider_blur {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    backdrop-filter: blur(14px);
}

.slider_pagination {
    position: absolute;
    bottom: -20px !important;
    margin: 0 auto;
    text-align: center;
    --swiper-pagination-color: #c06198 !important;
}

.swiper {
    height: 100%;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
