.info-row_container {
    display: flex;
    justify-content: space-between;
    padding: 8px;
}

.info-row_title {
    color: #818c99
}

.info-row_value {
    font-weight: 600;
    display: flex;
    min-width: 35%;
    justify-content: end;
    align-items: center;
}
