/* Контейнер чекбокса */
.checkbox_container {
    display: flex;
    flex-direction: column;
    margin: 8px 0;
}

/* Метка чекбокса */
.checkbox_label {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
}

/* Скрываем нативный чекбокс */
.checkbox_native {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

/* Кастомное отображение чекбокса */
.checkbox_custom {
    width: 20px;
    height: 20px;
    background: #f2f3f5;
    border: 1px solid #d5d6d8;
    border-radius: 4px;
    box-sizing: border-box;
    margin-right: 10px;
    position: relative;
    transition: background 0.2s, border-color 0.2s;
}

/* При наведении (опционально) */
.checkbox_label:hover .checkbox_custom {
    border-color: #a0a0a0;
}

/* Состояние :checked */
.checkbox_native:checked + .checkbox_custom {
    background: #c06198;
}

/* Отображаем галочку при checked */
.checkbox_native:checked + .checkbox_custom::after {
    content: "";
    position: absolute;
    left: 6px;
    top: 2px;
    width: 4px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/* Текст метки */
.checkbox_text {
    font-size: 16px;
    color: #333;
}

/* Сообщение об ошибке */
.checkbox_error-message {
    font-size: 14px;
    color: #ff0000;
    margin-top: 4px;
}
