/* Кнопка "Вверх" всегда поверх страницы */
.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
}

/* Стили для кнопки */
.scroll-to-top-button {
    background: rgba(0, 0, 0, 0.5); /* полупрозрачный фон */
    border: none;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    transition: background 0.3s;
    color: #fff;
}

/* Изменение фона при наведении */
.scroll-to-top-button:hover {
    background: rgba(0, 0, 0, 0.8);
}
