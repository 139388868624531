.housing_skeleton_item-container {
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  gap: 4px;
  box-shadow: 0px 2px 24px 0px #00000014;
  border-radius: 10px;
  @media (width >= 1024px) {
    width: 290px;
  }
}

.housing_skeleton_item-title {
  width: 100px;
  height: 24px;
}

.housing_skeleton_item-builder {
  width: 120px;
  height: 18px;
}

.housing_skeleton_item-address {
  width: 200px;
  height: 14px;
}

.housing_skeleton_item-image {
  width: 100%;
  padding: 40%;
  box-sizing: border-box;
}
