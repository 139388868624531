.textarea_container {

}

.textarea_main {
    all: unset;
    padding: 10px;
    width: 100%;
    min-height: 50px;
    box-sizing: border-box;
    background: #f2f3f5;
    border: 1px solid #d5d6d8;
    border-radius: 8px;
}

.textarea_error {
    border: 1px solid #ff0000;
    background: #ffdddd;
}

.textarea_error-message {
    font-size: 14px;
    color: #ff0000;
}

.textarea_label {
    margin-bottom: 4px;
}
