.housing_complex_container {
  padding: 0 20px;
}

.housing_complex_title {
  font-size: 24px;
}

.housing_complex_info {
}

.housing_complex_swyper {
  padding: 20px 0;
}

.housing_complex_description {
  white-space: pre-line;
  transition: all ease-in 0.2s;
}

.housing_complex_expanded {
  height: auto;
}
