.footer_container {
    padding: 20px 0;
    margin: 20px 20px 0;
    border-top: 1px solid #818c99;
    color: #818c99;
    display: flex;
    flex-direction: column;
    gap: 6px;
    a {
        all: unset;
        font-weight: 600;
    }
}
