.housing-complex_container {

}

.housing-complex_main-container {

  @media (width >= 1024px) {
    width: 50%;
  }
}
.housing-complex_plans-container {
  cursor: pointer;
  padding: 0 20px;
}

.housing-complex_installment-plan-container {
  padding: 0 20px;
  @media (width >= 1024px) {
    min-width: 350px;
  }
}

.housing-complex_back-link {
  all: unset;
  display: flex;
  align-items: center;
  padding: 0 20px;
  gap: 4px;
  text-decoration: none;
  margin-bottom: 16px;
  cursor: pointer;
  @media (width >= 1024px) {
    margin-top: 16px;
    width: 100%;
  }
}

.housing-complex_chevron {
  transform: rotate(180deg);
}
