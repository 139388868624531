.account-item_container {
    margin-bottom: 20px;
    padding-bottom: 20px;
    display: flex;
    height: 56px;
    gap: 16px;
    border-bottom: 1px solid #c8ced9;
}

.account-item_info {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
}

.account-item_title {
    font-size: 20px;
}

.account-item_description {
    font-size: 16px;
    color: #818c99;
}
