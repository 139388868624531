.sign-in_container {
  padding: 0;

  @media (width >= 1024px) {
    box-sizing: border-box;
    width: 400px;
    margin: 0 auto;
    padding: 0 20px;
  }
}

.sign-in_form-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: #fff;
  padding: 16px;
  border-radius: 10px;
  box-shadow: 0px 2px 24px 0px #00000014;
}

.sign-in_input-item {
}

.sign-in_link {
  all: unset;
  color: #c06198;
  font-weight: 600;
}

.sign-in_reg-advice {
  padding: 20px;
}

.sign-in_error {
  background: #ff00000f;
  border-radius: 8px;
}
