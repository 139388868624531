.main-page_container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (width >= 1024px) {
        margin: 0 auto;
        width: 800px;
    }
}

.main-page_content {
    width: 100%;
    @media (width >= 1024px) {
        width: 100%;
        display: flex;
    }
}

.main-page_title {
    margin: 20px 0;
    font-weight: 500;
    font-size: 32px;
}
