/* Анимация по высоте */
.usercard_socials {
    height: 0;             /* высота по умолчанию */
    overflow: hidden;
    transition: height 0.3s ease;
}

/* Остальные стили остаются прежними */
.usercard_container {
    align-items: center;
    box-shadow: 0px 2px 24px 0px #00000014;
    padding: 20px;
    border-radius: 10px;
    min-height: 56px;
    justify-content: space-between;
    position: relative;
}

.usercard_avatar {
}

.usercard_main-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: 100%;
}

.usercard_secondary-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    min-width: 130px;
    margin-top: 16px;
}

.usercard_name {
    font-size: 18px;
    font-weight: 500;
}

.usercard_contacts {
    all: unset;
}

.usercard_contact {
    all: unset;
    display: flex;
    align-items: center;
    height: 32px;
}

.usercard_row {
    display: flex;
    gap: 16px;
    align-items: center;
}

.usercard_phone-link,
.usercard_contact-link {
    text-decoration: none;
    color: #c06198;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.usercard_contact-link:hover {
    opacity: 0.8;
}

.usercard_toggle {
    margin-left: auto;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.usercard_chevron {
    transition: transform 0.3s ease;
    width: 24px;
    height: 24px;
    fill: #c06198;
    transform: rotate(90deg);
}

.usercard_chevron.open {
    transform: rotate(270deg);
}

