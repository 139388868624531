.button_main {
  display: flex !important;
  justify-content: center !important;
  all: unset;
  background: #c06198;
  color: #fff;
  padding: 10px 20px;
  border-radius: 6px;
  min-width: 84px;
  box-sizing: border-box;
  cursor: pointer;
}

.button_main:active {
  scale: 0.95;
  transition: all ease-in-out 0.05s;
}
.button_main:disabled {
  background: #818c99;
  transition: none;
  scale: 1;
}

.button_full-width {
  width: 100% !important;
}
