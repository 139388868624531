.housing-item_container {
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 2px 24px 0px #00000014;
  cursor: pointer;
  @media (width >= 1024px) {
    width: 290px;
  }
}

.housing-item_info {
  padding: 12px;
}

.housing-item_title {
  font-size: 20px;
  font-weight: 800;
}

.housing-item_builder {
  font-size: 16px;
}

.housing-item_address {
  font-size: 12px;
  color: #818c99;
}

.housing-item_default-image {
  padding: 40%;
}

.housing-item_image {
  width: 100%;
  aspect-ratio: 1 / 1;
}

.housing-item_image-container {
  aspect-ratio: 1 / 1;
  position: relative;
}

.housing-item_blur {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  backdrop-filter: blur(14px);
  overflow: hidden;
}
