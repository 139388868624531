.navbar_link-item {
    all: unset;
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    color: #818c99;
    padding: 10px 0;
}

.navbar_active-route {
    background: #c061988f;
    color: #fff;
    border-radius: 10px;
    padding: 10px;
}
