.payment_container {
    padding: 0 20px;
}

.payment_tariff-list {
    display: flex;
    justify-content: center;
}

.payment_tariff-container {
    max-width: 500px;
}

.payment_tariff {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 24px 0px #00000014;
}

.payment_tariff-title {
    font-size: 14px;
    padding: 10px;
    background: #eee;
    width: fit-content;
    border-radius: 20px;
}

.payment_tariff-description {
    font-size: 14px;
    color: #818c99;
}

.payment_tariff-price {
    margin-top: 20px;
    font-size: 52px;
    font-weight: 600;
    color: #c06198;
}

.payment_check-icon {
    width: 20px;
    height: 20px;
}

.payment_tariff-options {
    all: unset;
    display: flex;
    flex-direction: column;
    gap: 22px;

    li {
        all: unset;
        display: flex;
        gap: 8px;
        align-items: center;
        font-size: 14px;
        color: #818c99;
    }
}

.payment_tariff-option-text {
    width: 85%;
}

.payment_back-link {
    all: unset;
    margin-top: 16px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    gap: 4px;
    text-decoration: none;
    margin-bottom: 16px;
    cursor: pointer;
    @media (width >= 1024px) {
        width: 100%;
    }
}

.payment_error {
    background: #ff00000f;
    border-radius: 8px;
}

.payment_chevron {
    transform: rotate(180deg);
}
