.shared-housing_container {
  overflow: hidden;
  display: flex;
  gap: 6px;
  flex-direction: column;
  padding: 0 20px;
}

.shared-housing_title {
  padding: 24px 0;
  font-size: 20px;
  font-weight: 600;
}

.shared-housing_main-info {
  position: fixed;
  bottom: 0;
  background: #fff;
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  box-sizing: border-box;
  box-shadow: 0px 2px 24px 0px #00000014;

  .info-row_value {
    color: #c06198;
    font-size: 20px;
  }
}

.shared-housing_empty-space {
  min-height: 124px;
}
