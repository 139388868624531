.notification-container {
    position: fixed;
    bottom: -600px;
    background: #000000c7;
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    backdrop-filter: blur(5px);
    padding: 20px;
    box-sizing: border-box;
    gap: 8px;
    transition: 0.5s;
    animation: show 0.5s 1;
    z-index: 500;
}

.notification-title {
    color: #fff;
}

.notification-description {
    color: #fff;
}

.notification-close {
    position: absolute;
    color: #fff;
    top: 0;
    right: 0;
    padding: 6px;
    width: 24px;
    height: 24px;
}
.notification-link {
    all: unset;
    color: #c06198;
    font-weight: 600;

}
