.restore-password_container {
    padding: 0 20px;

    @media (width >= 1024px) {
        box-sizing: border-box;
        width: 400px;
        margin: 0 auto;
    }

    a {
        all: unset;
        color: #c06198;
        font-weight: 600;
        text-align: center;
    }
}

.restore-password_form-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    background: #fff;
    padding: 16px;
    border-radius: 10px;
    box-shadow: 0px 2px 24px 0px #00000014;
}

.restore-password_input-item {
}

.restore-password_description {
    margin: 20px 0;
    color: #818c99;
    font-size: 14px;
}

.restore-password_back-link {
    all: unset;
    display: flex;
    align-items: center;
    padding: 0 20px;
    gap: 4px;
    text-decoration: none;
    margin-bottom: 16px;
    @media (width >= 1024px) {
        width: 100%;
    }
}
