.contract-assignment-editor_container {
    padding: 0 20px;
}
.contract-assignment-item_button {
    margin: 16px 0;
}

.contract-assignment-editor_switcher {
    margin: 16px 0;
}
