/* Контейнер всего прайс-листа */
.pricelist_container {
    /* Дополнительные стили при необходимости */
}

/* Заголовок прайс-листа */
.pricelist_title {
    margin-top: 24px;
    font-size: 20px;
    font-weight: 600;
}

.pricelist_subtitle {
    font-size: 16px;
    color: #818c99;
    margin-bottom: 16px;
}

.period-header {/* устанавливаем уровень, выше чем у контейнера таблицы */
    font-size: 16px;
    margin: 10px 1px 0 1px;
    width: 50%;
    padding: 7px;
    border-radius: 10px 10px 0 0;
    background: #c061988f;
    color: #fff;
}


/* Контейнер всей таблицы */
.price-table-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 16px;
    border-radius: 8px;
    background-color: #fff;
}

/* Контейнер для таблицы */
.table-container {
    overflow-x: auto;
}

/* Стилизация таблицы */
.price-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
    box-shadow: 0px 2px 24px 0px #00000021;
}

/* Заголовки таблицы */
.price-table thead {
    background-color: #f5f5f5;
}

/* Заголовки с фиксированным положением */
.price-table thead th {
    position: sticky;
    top: 0;
    background-color: #f5f5f5;
    z-index: 3;
    padding: 12px;
    text-align: left;
    border: 1px solid #ccc;
    font-weight: bold;
}

/* Фиксированный (липкий) первый столбец */
.price-table th.sticky-col,
.price-table td.sticky-col {
    position: -webkit-sticky; /* для Safari */
    position: sticky;
    left: 0;
    background: #fff;
    z-index: 4;
}

/* Ячейки таблицы */
.price-table td {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
}

/* Чередование строк */
.price-table tbody tr:nth-child(even) {
    background-color: #fafafa;
}

/* Стилизация строк при наведении */
.price-table tbody tr:hover {
    background-color: #f0f0f0;
}
