.credits_summary {
    display: flex;
    justify-content: space-between;
}

.credits_chevron {
    display: flex;
    transform: rotate(90deg);
}
.credits_item {
    font-size: 14px;
    margin: 8px 4px;
}
