.contract-assignment-list-skeleton_container {
    all: unset;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    gap: 16px;
}

.contract-assignment-list-skeleton_item {
    all: unset;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 24px 0 #00000014;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    gap: 8px;
    overflow: hidden;
    padding: 10px;
    width: 100%;
    height: 120px;
}

.contract-assignment-list-skeleton_img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
}

.contract-assignment-list-skeleton_info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.contract-assignment-list-skeleton_title {
    width: 30%;
    height: 22px;
}

.contract-assignment-list-skeleton_description {
    width: 70%;
    height: 60px;
}
